import React from 'react';
import { FaLocationDot } from "react-icons/fa6";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import {
  FaLinkedin,
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
} from 'react-icons/fa';
import { Link } from 'react-scroll';

const Footer = () => {
  return (
    <div className="bg-black pl-[5%] pr-[2%] py-10" id='Footer' >
      <h1 className=" text-gray-400 font-bold text-xl mb-3">Contact Us</h1>
      <div className="border-b-2 w-[95%] mb-16" style={{ borderImage: 'linear-gradient(to right, #00df9a, #000000) 1' }}></div>
      <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 mb-20 text-gray-300">
        <a href='mailto:academicgeeks4@gmail.com' rel="noreferrer" className="flex items-center cursor-pointer hover:bg-gray-950 shadow-2xl hover:shadow-green-500 rounded-xl p-5">
          <AiOutlineMail size={40} color='gray' />
          <div className="flex flex-col ml-2">
            <h1 className="font-bold  text-gray-400">Email</h1>
            <p className=" text-white">academicgeeks4@gmail.com</p>
          </div>
        </a>
        <a href='tel:+923419530117' rel="noreferrer" className="flex items-center cursor-pointer hover:bg-gray-950 shadow-2xl hover:shadow-green-500 rounded-xl p-5">
          <BsTelephone size={40} color='gray' />
          <div className="flex flex-col ml-2">
            <h1 className=" font-bold text-gray-400">Phone</h1>
            <p className=" text-white">+92-341-9530117</p>
          </div>
        </a>
        <a href='https://maps.app.goo.gl/HxgrMbSGcFGKmWfF8' target="_blank" rel="noreferrer" className="flex cursor-pointer hover:bg-gray-950 shadow-2xl hover:shadow-green-500 rounded-xl p-5 items-center ">
          <FaLocationDot  size={40} color='gray' />
          <div className="flex flex-col ml-2">
            <h1 className=" font-bold text-gray-400">Location</h1>
            <p className=" text-white">Office 121, Batala Colony, Faisalabad, Pakistan</p>
          </div>
        </a>
      </div>
      <div className="border-b-2 w-[95%] mb-10" style={{ borderImage: 'linear-gradient(to right, #00df9a, #000000) 1' }}></div>

      <div className='max-w-[1240px] py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
        <div>
          <h1 className='w-full text-3xl font-bold text-[#00df9a]'>Academic<span className=' text-gray-400'> Geeks</span>!</h1>
          <p className='py-4'>We specialize in providing tailored educational solutions for busy professionals and working students. We understand the unique challenges faced by individuals who are balancing their studies with demanding jobs or personal commitments.</p>
          <div className='flex  justify-between md:w-[75%] my-6'>
            <a href="https://www.facebook.com/academicgeekspk" className="" target="_blank" without rel="noreferrer"><FaFacebookSquare size={30} /></a>
            <a href="https://www.instagram.com/academic_geeks/" className="" target="_blank" without rel="noreferrer"><FaInstagramSquare size={30} /></a>
            <a href="https://twitter.com/" className="" target="_blank" without rel="noreferrer"><FaTwitterSquare size={30} /></a>
            <a href="https://www.linkedin.com/company/academic-geeks " className="" target="_blank" without rel="noreferrer"><FaLinkedin size={30} /></a>
          </div>
        </div>
        <div className='lg:col-span-2 flex flex-wrap justify-between mt-6'>
          <div className='flex flex-col items-start mb-10'>
            <h6 className='font-medium text-gray-400'>Services</h6>
            <Link to="Services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Dissertation</Link>
            <Link to="Services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Assignment</Link>
            <Link to="Services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Presentation</Link>
            <Link to="Services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Online Exams</Link>
            <Link to="Services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Reports</Link>
            <Link to="Services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>IT Projects</Link>
          </div>
          <div className='flex flex-col items-start mb-10'>
            <h6 className='font-medium text-gray-400'>Support</h6>
            <Link
              to="Email"
              spy={true}
              smooth={true}
              offset={0}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Consultancy</Link>
            <Link
              to="Email"
              spy={true}
              smooth={true}
              offset={0}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Appointment</ Link>
            <Link
              to="Email"
              spy={true}
              smooth={true}
              offset={0}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Pricing</ Link>
            <Link
              to="Email"
              spy={true}
              smooth={true}
              offset={0}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Documentation</ Link>
            <Link
              to="Email"
              spy={true}
              smooth={true}
              offset={0}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>FAQ</Link>
          </div>
          <div className='flex flex-col items-start mb-10'>
            <h6 className='font-medium text-gray-400'>Universities</h6>
            <Link to='Universities'
              spy={true}
              smooth={true}
              offset={50}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>BPP University</Link>
            <Link to='Universities'
              spy={true}
              smooth={true}
              offset={50}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Coventry University</Link>
            <Link to='Universities'
              spy={true}
              smooth={true}
              offset={50}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>UWS</Link>
            <Link to='Universities'
              spy={true}
              smooth={true}
              offset={50}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Suffolk University</Link>
            <Link to='Universities'
              spy={true}
              smooth={true}
              offset={50}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>LSBU</Link>
            <Link to='Universities'
              spy={true}
              smooth={true}
              offset={50}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Nothampton University</Link>
          </div>
          <div className='flex flex-col items-start mb-10'>
            <h6 className='font-medium text-gray-400'>Subjects</h6>
            <Link to="Subjects"
              spy={true}
              smooth={true}
              offset={50}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Computer Science</Link>
            <Link to="Subjects"
              spy={true}
              smooth={true}
              offset={50}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Marketing and Finance</Link>
            <Link to="Subjects"
              spy={true}
              smooth={true}
              offset={50}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Accounts and Law</Link>
            <Link to="Subjects"
              spy={true}
              smooth={true}
              offset={50}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Health and Science </Link>
            <Link to="Subjects"
              spy={true}
              smooth={true}
              offset={50}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Business and Administration</Link>
            <Link to="Subjects"
              spy={true}
              smooth={true}
              offset={50}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Fashion Designing</Link>
            <Link to="Subjects"
              spy={true}
              smooth={true}
              offset={50}
              duration={500} className=' cursor-pointer py-2 text-sm hover:opacity hover:opacity-70 active:opacity-50'>Enviromental Science </Link>
          </div>
        </div>
      </div>
      <p className=' text-gray-300 text-center'>© 2024 Academic Geeks Designd and Developed By CodeVector Technologies.</p>
      <div className="flex flex-wrap gap-5 w-full justify-center">
      <p className=' text-gray-300 text-center'>+92-370-0183868</p>
      <p className=' text-gray-300 text-center'>codevectortechnologies@gmail.com</p>
      </div>
    </div>
  );
};

export default Footer;
