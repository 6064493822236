import React from 'react';
import whatsapp from '../assets/WhatsApp.png';
import skype from '../assets/skype.png';

const Chat = () => {
  return (
    <div className='fixed right-5 bottom-5'>
      <a href="skype:live:b7395241bf7d3c31?chat" target="_blank" rel="noreferrer"><img className=' hover:opacity-70 w-14' src={skype} alt="" /></a>
      <a href="https://wa.me/+923419530117" target="_blank" rel="noreferrer"><img className=' hover:opacity-70 w-16 mt-2' src={whatsapp} alt="" /></a>
    </div>
  )
}

export default Chat
