import React from 'react'
import img1 from "../assets/Services/1.jpeg";
import img2 from "../assets/Services/2.jpeg";
import img3 from "../assets/Services/3.jpeg";
import img4 from "../assets/Services/4.jpeg";
import img5 from "../assets/Services/5.jpeg";
import img6 from "../assets/Services/6.jpeg";



function Services() {
    return (
            <div className='p-[10%] bg-black' id='Services'>
                <h1 className=" text-4xl font-bold text-white">Our Services</h1>
                <div className="border-b-2 w-[100%] my-10" style={{ borderImage: 'linear-gradient(to right, #00df9a, #000000) 1' }}></div>
                <p className='pb-14 text-white'>At our company, we specialize in providing comprehensive academic support across a range of services. Whether you need assistance with your dissertation, assignments, or presentations, our expert team is here to help. We also offer robust support for online exams, ensuring you are well-prepared to succeed. Additionally, we provide thorough reports and IT project guidance to help you excel in your academic and professional endeavors. Trust us to deliver high-quality, tailored solutions that meet your specific needs.</p>
                <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8">
                    <button class="flex flex-col items-start w-80 my-5 hover:bg-gray-950 shadow-2xl hover:shadow-green-500 rounded-xl transition duration-400 hover:scale-105" >
                        <div className="overflow-hidden rounded-xl"><img class=" object-cover  w-80 h-40" src={img1} alt='' /></div>
                        <p class="text-2xl font-semibold mt-5 text-white mx-5">Dissertation</p>
                        <p class="text-gray-300 mt-4 text-start m-5">Our dissertation services offer comprehensive support from topic selection to final editing, ensuring your research is well-structured, thoroughly analyzed, and meets all academic standards. Our experienced writers and advisors guide you through every step to help you produce a compelling and original dissertation.</p>
                    </button>
                    <button class="flex flex-col items-start w-80 my-5 hover:bg-gray-950 shadow-2xl hover:shadow-green-500 rounded-xl transition duration-400 hover:scale-105" >
                        <div className="overflow-hidden rounded-xl"><img class=" object-cover  w-80 h-40" src={img2} alt='' /></div>
                        <p class="text-2xl font-semibold mt-5 text-white mx-5">Assignment</p>
                        <p class="text-gray-300 mt-4 text-start m-5">We provide expert assistance with assignments across various subjects, delivering high-quality, well-researched, and properly formatted work. Whether it's an essay, case study, or research paper, our team ensures timely delivery and adherence to your academic requirements.</p>
                    </button>
                    <button class="flex flex-col items-start w-80 my-5 hover:bg-gray-950 shadow-2xl hover:shadow-green-500 rounded-xl transition duration-400 hover:scale-105" >
                        <div className="overflow-hidden rounded-xl"><img class=" object-cover  w-80 h-40" src={img3} alt='' /></div>
                        <p class="text-2xl font-semibold mt-5 text-white mx-5">Presentation</p>
                        <p class="text-gray-300 mt-4 text-start m-5">Our presentation services help you create engaging, professional, and visually appealing slides. We focus on clear communication of your ideas, incorporating effective design elements and ensuring your presentation is both informative and impactful.</p>
                    </button>
                    <button class="flex flex-col items-start w-80 my-5 hover:bg-gray-950 shadow-2xl hover:shadow-green-500 rounded-xl transition duration-400 hover:scale-105" >
                        <div className="overflow-hidden rounded-xl"><img class=" object-cover  w-80 h-40" src={img4} alt='' /></div>
                        <p class="text-2xl font-semibold mt-5 text-white mx-5">Online Exams</p>
                        <p class="text-gray-300 mt-4 text-start m-5">We offer reliable support for online exams, including preparation strategies and real-time assistance during the exam. Our services aim to enhance your performance by providing practice tests, study materials, and expert guidance to ensure you are fully prepared.</p>
                    </button>
                    <button class="flex flex-col items-start w-80 my-5 hover:bg-gray-950 shadow-2xl hover:shadow-green-500 rounded-xl transition duration-400 hover:scale-105" >
                        <div className="overflow-hidden rounded-xl"><img class=" object-cover  w-80 h-40" src={img5} alt='' /></div>
                        <p class="text-2xl font-semibold mt-5 text-white mx-5">Reports</p>
                        <p class="text-gray-300 mt-4 text-start m-5">Our report writing services cover a wide range of topics and formats, providing clear, concise, and insightful reports tailored to your specifications. We ensure thorough research and proper structuring to deliver reports that effectively communicate your findings and analysis.</p>
                    </button>
                    <button class="flex flex-col items-start w-80 my-5 hover:bg-gray-950 shadow-2xl hover:shadow-green-500 rounded-xl transition duration-400 hover:scale-105" >
                        <div className="overflow-hidden rounded-xl"><img class=" object-cover  w-80 h-40" src={img6} alt='' /></div>
                        <p class="text-2xl font-semibold mt-5 text-white mx-5">IT Projects</p>
                        <p class="text-gray-300 mt-4 text-start m-5">We provide specialized support for IT projects, from planning and development to implementation and testing. Our team of IT experts helps you navigate complex technical challenges, ensuring your projects are completed efficiently and to the highest standard.</p>
                    </button>
                </div>
            </div>
    )
}

export default Services
