export const Questions = [
    {
      question: "What price does you charge per 1000 words?",
      answer: "The cost of your assignment can vary based on its difficulty level. To get an accurate quote as soon as possible, please reach out to our customer care service. Our team is ready to assist you and provide detailed information tailored to your specific needs."
    },
    {
      question: "Which university students do you cater?",
      answer: "We collaborate with universities globally, with a primary focus on institutions in the UK and Australia. Our extensive experience with these regions ensures that we understand and meet their specific academic standards and requirements."
    },
    {
      question: "Which fields or subjects do you cater?",
      answer: "We offer content writing services across a wide range of subjects, including management, project management, accounting, finance, law, medical, engineering, IT, and more. Our diverse team of experts ensures high-quality, specialized content tailored to your academic or professional needs."
    },
    {
      question: "How much time does it takes you to deliver an assignment?",
      answer: "The time required to complete a task depends entirely on the difficulty of the file. However, we can assure you that we are equipped to handle short and urgent deadlines effectively, delivering high-quality work within the required timeframe"
    },
    {
      question: "Does you charge in PKR, INR or USD?",
      answer: "We exclusively deal in UK Pounds and USD for all our transactions. This ensures a streamlined and efficient payment process for our clients, accommodating the most commonly used currencies in our primary markets"
    },
    {
      question: "What kind of Tasks you do?",
      answer: "We handle a wide range of study-related tasks, including dissertations, assignments, presentations, online exams, reports, and IT projects. Our comprehensive services are designed to support your academic success across various disciplines and requirements."
    }
    
  ]

  export const universities = [
    {
      src : "",
      name : ""
      
    },
  ]
  