import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/CSS/Slider.css";
import uni2 from "../assets/Universities/2.png";
import uni3 from "../assets/Universities/3.png";
import uni4 from "../assets/Universities/4.png";
import uni5 from "../assets/Universities/5.png";
import uni6 from "../assets/Universities/6.png";
import uni7 from "../assets/Universities/7.png";
import uni8 from "../assets/Universities/8.png";
import uni9 from "../assets/Universities/9.png";
import uni10 from "../assets/Universities/10.jpg";
import uni11 from "../assets/Universities/11.png";



const University = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    };
    return (
        <div className='p-[10%] bg-white mb-28 mt-10' id='Universities'>
            <h1 className=" text-4xl font-bold">Universities</h1>
            <div className="border-b-2 w-[100%] my-10" style={{ borderImage: 'linear-gradient(to right, #00df9a, #000000) 1' }}></div>
            <p className='pb-14'>We collaborate with universities globally, with a primary focus on institutions in the UK and Australia. Here are some universities where our students have achieved outstanding results and expressed high satisfaction with our services.</p>
            <div className="slider-container">
                <Slider {...settings}>
                    <div>
                        <img className=' w-[70%]' src={uni10} alt="" />
                    </div>
                    <div>
                        <img className=' w-[70%]' src={uni11} alt="" />
                    </div>
                    <div>
                        <img className=' w-[70%]' src={uni3} alt="" />
                    </div>
                    <div className=' items-center'>
                        <img className=' w-[70%]' src={uni2} alt="" />
                    </div>
                    <div>
                        <img className=' w-[70%]' src={uni4} alt="" />
                    </div>
                    <div>
                        <img className=' w-[70%]' src={uni5} alt="" />
                    </div>
                    <div>
                        <img className=' w-[70%]' src={uni6} alt="" />
                    </div>
                    <div>
                        <img className=' w-[70%]' src={uni7} alt="" />
                    </div>
                    <div>
                        <img className=' w-[70%]' src={uni8} alt="" />
                    </div>
                    <div>
                        <img className=' w-[70%]' src={uni9} alt="" />
                    </div>
                    <div>
                        <img className=' w-[70%]' src={uni4} alt="" />
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default University      