import React from "react";
import Accordion from "./Accordion";
import { Questions } from "../api";
import FAQI from "../assets/FAQ.jpeg";

const FAQ = () => {
  return (
    <div className="bg-black p-[10%]" id="FAQ">
      <h1 className=" text-gray-300 text-4xl font-bold">Frequently Asked Questions</h1>
      <div className="border-b-2 w-[100%] my-10" style={{ borderImage: 'linear-gradient(to right, #00df9a, #000000) 1' }}></div>
    <div className="   grid md:grid-cols-2 items-center"> 
      <img className=" w-[80%] " src={FAQI} alt="" />
      <div className="flex flex-wrap px-[5%] py-10 ">
        { Questions.map((items,key) => { return(
          <Accordion
          title={items.question}
          answer={items.answer}
          key={key}
        />
        )})
        
      }
      </div>
    </div>
  </div>
  );
};

export default FAQ;
