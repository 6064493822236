import React from 'react';
import { Link } from 'react-scroll';
import { TypeAnimation } from "react-type-animation";


const Hero = () => {
  return (
    <div className='text-white bg-black' id='Home'>
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <p className='text-[#00df9a] font-bold p-2'>
          GROWING WITH ACADEMIC GEEKS!
        </p>
        <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>
          Don't Have Time For Study?
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
            We will do your
          </p>
          <TypeAnimation
          className=' md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
              sequence={[
                "Dissertation",
                1500,
                "Presentation",
                1500,
                "Assignment",
                1500,
                "Exams",
                1500,
                "Reports",
                1500,
                
              ]}
              wrapper="span"
              speed={20}
              repeat={Infinity}
            />
        </div>
        <p className='md:text-2xl text-xl font-bold text-gray-500'>Are you busy or focusing on career? We are your study partners...</p>
        <Link
          to="Email"
          spy={true}
          smooth={true}
          offset={0}
          duration={500} className='bg-[#00df9a] cursor-pointer hover:opacity-70 w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'>Get Started</Link>
      </div>
    </div>
  );
};

export default Hero;
