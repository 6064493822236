import React from 'react';
import Laptop from '../assets/laptop.jpg';
import toheed from '../assets/CEO.jpg';
import haris from '../assets/CFO.jpg';

const Analytics = () => {
  return (
    <div className='w-full bg-white py-16 px-4' id='About'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={Laptop} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#00df9a] font-bold '>Who we are?</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>About Academic Geeks</h1>
          <p>
          At our company, we specialize in providing tailored educational solutions for busy professionals and working students. We understand the unique challenges faced by individuals who are balancing their studies with demanding jobs or personal commitments. Our innovative programs and flexible learning options are designed to fit seamlessly into their hectic schedules, enabling them to achieve their academic goals without compromising their professional responsibilities. By offering personalized support and resources, we empower our students to excel in their studies and advance in their careers simultaneously.
          </p>
          <button className='bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Get Started</button>
        </div>
      </div>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 mt-20'>
        <img className='w-[70%] mx-auto my-4 rounded-3xl' src={toheed} alt='/' />
        <div className='flex flex-col justify-center '>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'style={{ background: 'linear-gradient(to right, #00df9a, #000000)', WebkitBackgroundClip: 'text', color: 'transparent'}}>CEO</h1>
          <div className="border-b-2 w-[100%]" style={{ borderImage: 'linear-gradient(to right, #00df9a, #000000) 1' }}></div>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Toheed Javed</h1>
          <p>
          From the very beginning, our mission has been clear: to deliver exceptional content that not only meets but exceeds the expectations of our clients. We understand that in today’s digital age, content is more than just words on a page—it is a powerful tool that can shape perceptions, drive engagement, and foster meaningful connections.
          </p>
        </div>
      </div>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 '>
      <img className='w-[70%]  mx-auto my-4 rounded-3xl md:hidden mt-10' src={haris} alt='/' />
        <div className='flex flex-col justify-center '>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'style={{ background: 'linear-gradient(to right, #00df9a, #000000)', WebkitBackgroundClip: 'text', color: 'transparent'}}>CFO</h1>
          <div className="border-b-2 w-[100%]" style={{ borderImage: 'linear-gradient(to right, #00df9a, #000000) 1' }}></div>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Muhammad Haris Mir</h1>
          <p>
          Our commitment to fiscal responsibility ensures that we are well-positioned to invest in the best talent, innovative technologies, and comprehensive training programs. By doing so, we empower our team of writers, editors, and content strategists to deliver the highest quality content that meets the diverse needs of our clients.          </p>
        </div>
        <img className='w-[70%]  mx-auto my-4 rounded-3xl hidden md:block' src={haris} alt='/' />
      </div>
      
    </div>
  );
};

export default Analytics;
