import React from 'react';
import University from './components/University';
import Analytics from './components/Analytics';
import Cards from './components/Cards';
import Services from './components/Services';
import Footer from './components/Footer';
import Hero from './components/Hero';
import MoveUp from './components/MoveUp';
import Navbar from './components/Navbar';
import Counter from './components/Counter';
import FAQ from './components/FAQ';
import Email from './components/Email';
import Chat from './components/Chat';

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <MoveUp />
      <Cards />
      <Services />
      <University />
      <Counter />
      <Analytics />
      <FAQ />
      <Email />
      <Chat />
      <Footer />

    </div>
  );
}

export default App;
