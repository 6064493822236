import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className='sticky z-10 top-0 bg-black flex justify-between items-center h-20 px-5 md:px-20 opacity-80 text-white'>
      <h1 className='w-full text-3xl font-bold text-[#00df9a]'>Academic<span className=' text-gray-400'> Geeks</span>!</h1>
      <div className='hidden md:flex'>
        <Link
          to='Home'
          spy={true}
          smooth={true}
          offset={10}
          duration={500} activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 hover:opacity-70 cursor-pointer'>Home</Link>
        <Link
          to="Subjects"
          spy={true}
          smooth={true}
          offset={50}
          duration={500} activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 hover:opacity-70 cursor-pointer'>Subjects</Link>
        <Link
          to="Services"
          spy={true}
          smooth={true}
          offset={50}
          duration={500} activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 hover:opacity-70 cursor-pointer'>Services</Link>
        <Link
          to='Universities'
          spy={true}
          smooth={true}
          offset={50}
          duration={500} activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 hover:opacity-70 cursor-pointer'>Universities</Link>
        <Link
          to="Achievements"
          spy={true}
          smooth={true}
          offset={50}
          duration={500} activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 hover:opacity-70 cursor-pointer'>About</Link>
        <Link
          to="FAQ"
          spy={true}
          smooth={true}
          offset={50}
          duration={500} activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 hover:opacity-70 cursor-pointer'>FAQ</Link>
        <Link
          to="Footer"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500} activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 hover:opacity-70 cursor-pointer'>Contact</Link>
      </div>
      <div onClick={handleNav} className='block md:hidden'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <ul className={nav ? 'fixed left-0 top-0 w-[70%] flex flex-col h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'flex flex-col ease-in-out duration-500 fixed left-[-100%] '}>
        <h1 className='w-full text-3xl px-1 py-3 font-bold text-[#00df9a]'>Academic<span className=' text-gray-400'> Geeks</span>!</h1>
        <Link
          to="Home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 border-b border-gray-600'>Home</Link>
        <Link
          to="Subjects"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 border-b border-gray-600'>Subjects</Link>
        <Link
          to="Services"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 border-b border-gray-600'>Services</Link>
        <Link
          to="Universities"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 border-b border-gray-600'>Universites</Link>
        <Link
          to="Achievements"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 border-b border-gray-600'>About</Link>
        <Link
          to="FAQ"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 border-b border-gray-600'>FAQ</Link>
        <Link
          to="Footer"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          activeClass=" underline underline-offset-8 text-green-500 font-bold" className='p-4 border-b border-gray-600'>Contact</Link>
      </ul>
    </div>
  );
};

export default Navbar;
