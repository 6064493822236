"use client"
import React, { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

const Counter = () => {
    const [counterOn, setCounterOn] = useState(false);

    return (
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            <div className="bg-black pl-[10%] pr-[10%] py-24" id='Achievements'>
                <h1 className="text-gray-300 text-4xl font-bold py-3">Achievements</h1>
                <div className="border-b-2 w-[100%] my-10" style={{ borderImage: 'linear-gradient(to right, #00df9a, #000000) 1' }}></div>
                <p className="text-white pb-14">
                    Academic Geeks has firmly positioned itself at the forefront of educational excellence, reaching significant milestones since its establishment in August 2017. Our dedication to quality has earned us recognition and accolades, solidifying our reputation as a trusted leader in online education. We take great pride in the success of numerous learners who have thrived through our courses, gaining the knowledge and skills needed to excel in various fields.
                </p>
                <div className='grid lg:grid-cols-4 md:grid-cols-2' id="about">
                    <div className="">
                        <h1 className="text-gray-200 text-6xl font-bold">
                            {counterOn && <CountUp start={0} end={380} duration={4} delay={0} />}+
                        </h1>
                        <p className="text-gray-200 font-semibold">Projects Completed</p>
                    </div>
                    <div className="">
                        <h1 className="text-gray-200 text-6xl font-bold">
                            {counterOn && <CountUp start={0} end={87} duration={4} delay={0} />}%
                        </h1>
                        <p className="text-gray-200 font-semibold">Happy clients</p>
                    </div>
                    <div className="">
                        <h1 className="text-gray-200 text-6xl font-bold">
                            {counterOn && <CountUp start={0} end={20} duration={4} delay={0} />}+
                        </h1>
                        <p className="text-gray-200 font-semibold">Team Members</p>
                    </div>
                    <div className="">
                        <h1 className="text-gray-200 text-6xl font-bold">
                            {counterOn && <CountUp start={0} end={230} duration={4} delay={0} />}+
                        </h1>
                        <p className="text-gray-200 font-semibold">Cups of Tea</p>
                    </div>
                </div>
            </div>
        </ScrollTrigger>
    );
};

export default Counter;
